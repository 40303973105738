import JsBarcode from "jsbarcode";

import moment from "moment";

// Utils
import { formatDate } from "../../utils/dateUtils";
import { truncateString } from "react-lib/apps/HISV3/common/CommonInterface";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";
import CONFIG from "config/config";

const FORM_NAME = "ORAppointmentForm";
const LOGO_HEIGHT = 30;

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

type ORAppointmentFormProps = {
  language: string; // TH || EN
  patientFullName?: string;
  title?: string;
  patientHn?: string;
  patientBirthday?: string;
  patientCoverage?: string;
  appointmentDate?: string;
  appointmentTime?: string;
  doctor?: string;
  operatingCase?: string;
  admitDate?: string;
  admitTime?: string;
  anesthesiaMethodName?: string;
  hideBeforeAnesthesia?: boolean;
  divisionName?: string;
  divisionDetail?: string;
  remark?: any;
  npo?: string;
  npoDetail?: string;
  staff?: string;
  //
  contentId?: string;
  division?: string;
  appointmentType?: string;
  operatingType?: string;
  hideNote?: boolean;
  note?: string;
};

const ORAppointmentForm = async (props: ORAppointmentFormProps) => {
  console.log("ORAppointmentForm props: ", props);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);

  const contentHeaderCU = [
    {
      margin: [15, 0, 5, 0],
      table: {
        widths: ["77%", "*", "*"],
        body: [
          [
            {
              stack: [
                {
                  field_1: props.language === "TH" ? "วันที่นัดหมายผ่าตัด" : "Operative date",
                  value_1: props.appointmentDate || "",
                  field_2: props.language === "TH" ? "เวลาผ่าตัด" : "Operative time",
                  value_2: props.appointmentTime || "",
                },
                {
                  field_1: props.language === "TH" ? "แพทย์ผ่าตัด" : "Surgeon",
                  value_1: props.doctor || "",
                  field_2: props.language === "TH" ? "ประเภทเคส" : "Case",
                  value_2: props.operatingCase || "",
                },
                props.operatingCase === "IPD"
                  ? {
                      field_1: props.language === "TH" ? "วันที่นัด Admit" : "Admission date",
                      value_1: props.admitDate || "",
                      field_2: props.language === "TH" ? "เวลาที่นัด Admit" : "Admission time",
                      value_2: props.admitTime || "",
                    }
                  : {
                      field_1: " ",
                      value_1: " ",
                      field_2: " ",
                      value_2: " ",
                    },
                {
                  field_1: props.language === "TH" ? "วิธีระงับความรู้สึก" : "Anesthesia type",
                  value_1: props.anesthesiaMethodName || "",
                  field_2: "",
                  value_2: "",
                },
              ].map((item) => ({
                columns: [
                  { width: "26%", text: item.field_1, style: "fieldKey" },
                  { width: "36%", text: item.value_1, style: "fieldValue" },
                  { width: "20%", text: item.field_2, style: "fieldKey" },
                  { width: "14%", text: item.value_2, style: "fieldValue" },
                ],
              })),
              border: [false, false, false, false],
            },
            {
              alignment: "center",
              margin: [0, 5, 0, 0],
              stack:
                props.anesthesiaMethodName === "GA" && CONFIG.COMPANY === "CU"
                  ? [
                      { width: 40, image: "before_treatment" },
                      { text: "ข้อมูลก่อนเข้ารักษา", style: "qrCodeLable" },
                    ]
                  : [{ text: "", preserveLeadingSpaces: true }],
              border: [false, false, false, false],
            },
            {
              alignment: "center",
              margin: [0, 5, 0, 0],
              stack:
                !props.hideBeforeAnesthesia && // issue 67782
                props.anesthesiaMethodName === "GA" &&
                CONFIG.COMPANY === "CU"
                  ? [
                      { width: 40, image: "before_anesthesia" },
                      { text: "คำแนะนำก่อนดมยา", style: "qrCodeLable" },
                    ]
                  : [{ text: " ", preserveLeadingSpaces: true }],
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      margin: [20, 0, 5, 0],
      stack: [
        {
          columns: [
            {
              width: "19.5%",
              text: props.language === "TH" ? "แผนกที่ติดต่อ" : "Location for contact",
              style: "fieldKey",
            },
            {
              width: "*",
              text: `${props.divisionName || ""} ${props.divisionDetail}`,
              style: "fieldValue",
            },
          ],
        },
      ],
    },
  ];

  const appointmentDetail = () => {
    if ((props.remark || []).length === 0) {
      return contentHeaderCU;
    } else {
      let remark = [];
      let remarkLength = props.remark.length;
      let cloneData = [...props.remark];

      for (let i = 0; i < remarkLength; i++) {
        if (i === 0) {
          let remarkData = (cloneData || [])
            .splice(i, i + 5)
            .map((note: string, index: number) => ({
              text: note || "",
              style: "miniFieldValue",
            }));

          let headerDetail = () => {
            return [
              {
                margin: [20, 0, 5, 0],
                text: props.language === "TH" ? "คำแนะนำในการปฏิบัติตัว" : "Suggestions",
                style: "fieldKey",
                width: "*",
              },
              {
                margin: [20, 0, 5, 0],
                ul: remarkData,
              },
            ];
          };

          remark.push([...contentHeaderCU, ...headerDetail()]);

          i = i + 4;
        } else {
          let remarkData = (cloneData || []).splice(0, 11).map((note: string, index: number) => ({
            text: note || "",
            style: "miniFieldValue",
          }));

          remark.push([
            {
              margin: [20, 0, 5, 0],
              pageBreak: "before",
              text: props.language === "TH" ? "คำแนะนำในการปฏิบัติตัว" : "Suggestions",
              style: "fieldKey",
              width: "*",
            },
            {
              margin: [20, 0, 5, 0],
              ul: remarkData,
            },
          ]);

          i = i + 9; // Old: i = i + 10;
        }
      }

      remark.push({
        margin: [30, 0, 0, 0],
        text: [
          {
            text: `${props.npo || ""} ${props.npoDetail || ""}`,
            style: "fieldKey",
            width: "*",
          },
        ],
      });

      return remark || [];
    }
  };

  return {
    pageSize: "A5",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 13,
    },
    pageMargins: [10, 118, 10, 40],
    styles: {
      facultyHeader: {
        fontSize: 20,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 14,
      },
      fieldValue: {
        fontSize: 14,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 12.5,
      },
      miniFieldValue: {
        fontSize: 13,
      },
      qrCodeLable: {
        fontSize: 8,
      },
    },
    header: {
      margin: [10, 5, 10, 0],
      stack: [
        {
          stack: CONFIG.HIDE_COMPANY_LOGO_PRINT
            ? [
                {
                  columns: [null],
                },
              ]
            : CONFIG.COMPANY === "CU"
            ? [
                {
                  columns: [
                    {
                      margin: [10, 0, 0, 0],
                      width: logoResize.width,
                      height: LOGO_HEIGHT,
                      image: "logo",
                      alignment: "left",
                    },
                    {
                      margin: [-10, 5, 0, 0],
                      width: "*",
                      stack: [
                        {
                          text:
                            props.language === "TH"
                              ? "จุฬาลงกรณ์มหาวิทยาลัยโรงพยาบาลคณะทันตแพทย์"
                              : "Faculty of Dentistry, Chulalongkorn University",
                          style: "facultyHeader",
                          alignment: "center",
                        },
                        {
                          text:
                            props.title ||
                            (props.language === "TH" ? "ใบนัดหมายผ่าตัด" : "Operative Appointment"),
                          style: "facultyHeader",
                          alignment: "center",
                        },
                      ],
                    },
                  ],
                },
              ]
            : [
                {
                  margin: [10, 0, 0, 0],
                  width: logoResize.width,
                  height: LOGO_HEIGHT,
                  image: "logo",
                  alignment: "left",
                },
                {
                  margin: [-10, 5, 0, 0],
                  width: "*",
                  stack: [
                    {
                      text:
                        props.title ||
                        (props.language === "TH" ? "ใบนัดหมายผ่าตัด" : "Operative Appointment"),
                      style: "facultyHeader",
                      alignment: "center",
                    },
                  ],
                },
              ],
        },
        {
          margin: [0, 5, 0, 0],
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  margin: [20, 3, 20, 6],
                  border: [true, true, true, false],
                  stack: [
                    {
                      stack: [
                        {
                          field_1: "HN",
                          value_1: props.patientHn || "",
                          field_2: props.language === "TH" ? "ชื่อ-นามสกุล" : "Name",
                          value_2: props.patientFullName || "",
                        },
                        {
                          field_1: props.language === "TH" ? "วันเดือนปีเกิด" : "Birthdate",
                          value_1: props?.patientBirthday || "",
                          field_2: props.language === "TH" ? "สิทธิการรักษา" : "Coverage",
                          value_2: props.patientCoverage || "",
                        },
                      ].map((item) => ({
                        columns: [
                          { width: "16.5%", text: item.field_1, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_1, style: "fieldValue" },
                          { width: "16.5%", text: item.field_2, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_2, style: "fieldValue" },
                        ],
                      })),
                    },
                  ],
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["100%"],
          heights: 35, // 135
          body: [
            [
              {
                stack: appointmentDetail(),
              },
            ],
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        // margin: [0, currentPage === 1 ? 0 : 77.5, 0, 0],
        stack: [
          {
            stack: [
              {
                margin: [20, 0, 0, 0],
                text:
                  props.language === "TH"
                    ? "*กรุณายื่นบัตรนัดที่จุดคัดกรองทุกครั้งที่เข้ารับบริการ *กรุณาลงทะเบียนก่อนเวลานัดหมายอย่างน้อย 30 นาที"
                    : "Firstly, please go to triage station and register before appointment time at least 30 min",
                style: "miniFieldValue",
              },
              {
                columns: [
                  {
                    margin: [20, 0, 0, 0],
                    text: [
                      {
                        text: props.language === "TH" ? "ผู้ออกใบนัด: " : "Printed by: ",
                        style: "miniFieldValue",
                      },
                      { text: props.staff || "", style: "miniFieldValue" },
                    ],
                  },
                  {
                    alignment: "right",
                    margin: [0, 0, 20, 0],
                    text: [
                      {
                        text:
                          props.language === "TH"
                            ? `วัน-เวลาที่พิมพ์: ${formatDate(moment())} `
                            : `Printed date: ${moment().format("DD/MM/YYYY")} `,
                        style: "miniFieldValue",
                      },
                      { text: moment().format("[[]HH:mm[]]"), style: "miniFieldValue" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
      before_anesthesia: origin + "/static/images/before_anesthesia.png",
      before_treatment: origin + "/static/images/before_treatment.jpg",
    },
  };
};

export default ORAppointmentForm;
